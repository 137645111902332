import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MeetingViewComponent } from './meeting-view/meeting-view.component';

import { GuardGuard } from './guard.guard';
import { LoginComponent } from './login/login.component';
import { PlanListComponent } from './plan-list/plan-list.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'meeting-view/:id', component: MeetingViewComponent, canActivate: [GuardGuard] },
  { path: 'plan-list', component: PlanListComponent, canActivate: [GuardGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
