export class Group {
    id: string;
    displayName: string;
    plans: Plan[];
}

export class Plan {
    id: string;
    title: string;
    buckets: Bucket[]
}

export class Bucket {
    id: string;
    name: string;
    orderHint: string;
    planId: string;
    tasks: Task[];
}
  
export class Task {
    activeChecklistItemCount: number;
    appliedCategories: any;
    assigneePriority: string;
    assignments: any;
    bucketId: string;
    checklistItemCount: number;
    completedBy: any;
    completedDateTime: string;
    conversationThreadId: string;
    createdBy: any;
    createdDateTime: string;
    dueDateTime: string;
    hasDescription: boolean;
    id: string;
    orderHint: string;
    percentComplete: number;
    planId: string;
    previewType: string;
    referenceCount: number;
    startDateTime: string;
    title: string;
}

export class User {
    id: string;
    displayName: string;
    email: string;
    avatar: string;
}