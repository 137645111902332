import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';

import { AuthService } from './auth.service';
import { Bucket, Group, Plan, Task, User } from './graph-types';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  private graphClient: Client;
  constructor(private authService: AuthService) {
    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        let token = await this.authService.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token)
        {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });
  }

  private get(path, fx){
    return this.graphClient
      .api(path)
      .get()
      .then(fx)
      .catch(e=>console.log(JSON.stringify(e, null, 2)))
  }

  getGroups(): Promise<Group[]> {
    return this.get('/me/memberOf', r => 
      r.value
        .filter(x => x['@odata.type'] == "#microsoft.graph.group")
        .map(x => 
          <Group>{
            "id": x.id,
            "displayName": x.displayName
          }
        )
      )
  }

  getGroupPlans(groupId): Promise<Plan[]> {
    return this.get(`/groups/${groupId}/planner/plans`, r => 
      r.value
        .map(x => 
          <Plan>{
            "id": x.id,
            "title": x.title
          }
        )
      )
  }

  async getGroupsWithPlans(): Promise<Group[]> {
    let groups =  await this.getGroups()
    groups = await Promise.all(groups.map(async g => {
      g.plans = await this.getGroupPlans(g.id)
      return g
    }))
    return groups.filter(x=>x.plans.length > 0)
  }

  getPlan(planId): Promise<Plan> {
    return this.get(`/planner/plans/${planId}`, x => <Plan>{
      "id": x.id,
      "title": x.title
    })
  }

  getUser(userId): Promise<User> {
    return this.get(`/users/${userId}`, x => <User>{
      "id": x.id,
      "displayName": x.displayName
    })
  }

  getBucket(bucketId): Promise<Bucket> {
    return this.get(`/planner/buckets/${bucketId}`, x => <Bucket>{
      "id": x.id,
      "name": x.name,
      "orderHint": x.orderHint,
      "planId": x.planId
    })
  }

  getBuckets(planId): Promise<Bucket[]> {
    return this.get(`/planner/plans/${planId}/buckets`, r =>
    r.value
        .map(x => <Bucket>{
          "id": x.id,
          "name": x.name,
          "orderHint": x.orderHint,
          "planId": x.planId
        })
        .sort((bucket1, bucket2) => this.ordinalCompare(bucket1.orderHint, bucket2.orderHint))
    )
  }

  getBucketTasks(bucketId: string, excludeCompleted:Boolean = true): Promise<Task[]> {
    return this.get(`/planner/buckets/${bucketId}/tasks`, r => {
      let tasks = r.value
      if(excludeCompleted){
        tasks = tasks.filter(task => !task.completedDateTime)
      }
      return tasks
    })
  }

  getTask(taskId: string): Promise<Task> {
    return this.get(`/planner/tasks/${taskId}`, x => x)
  }

  
  async populateUsersForTask(task: Task): Promise<Task> {
    task.assignments = await Promise.all(Object.keys(task.assignments).map(this.getUser,this))
    return task
  }

  async getFullTasks(bucketId): Promise<Task[]> {
    let tasks = await this.getBucketTasks(bucketId)
    tasks = await Promise.all(tasks.map(this.populateUsersForTask,this))
    return tasks
  }

  async getFullBuckets(planId): Promise<Bucket[]> {
    let buckets = await this.getBuckets(planId)
    buckets = await Promise.all(buckets.map(async bucket => {
      bucket.tasks = await this.getFullTasks(bucket.id)
      return bucket
    }))
    return buckets
  }

  async getFullPlan(planId): Promise<Plan> {
    let plan =  await this.getPlan(planId)
    plan.buckets = await this.getFullBuckets(plan.id)
    return plan
  }

  //They handle ordering pretty strangely, requiring this function to figure out sort order
  ordinalCompare(str1, str2) {
    for(var i = 0; i < str1.length; i++){
      var code1 = str1.charCodeAt(i);
      var code2 = str2.charCodeAt(i);
      if(code1 < code2){return 1};
      if(code1 > code2){return -1};
    }
    if(str1.length == str2.length){
      return 0;
    } else if(!isNaN(str2.charCodeAt(i + 1))) {
      return -1;
    } else {
      return 1;
    }
  }
}
