import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { GraphService } from '../graph.service';
import { Bucket, Group, Plan, Task, User } from '../graph-types';

@Component({
  selector: 'app-meeting-view',
  templateUrl: './meeting-view.component.html',
  styleUrls: ['./meeting-view.component.scss']
})
export class MeetingViewComponent implements OnInit {
  
  planId: string
  plan: Plan = new Plan

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private graphService: GraphService
  ) { }

  ngOnInit() {
    this.planId =this.route.snapshot.paramMap.get('id');
    this.refreshPlan()
  }

  refreshPlan() {
    this.graphService.getFullPlan(this.planId).then(plan => {this.plan = plan})
  }


}
