import { Component, OnInit } from '@angular/core';
import { GraphService } from '../graph.service';

import { Group } from '../graph-types';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss']
})
export class PlanListComponent implements OnInit {
  groups: Group[]

  constructor(public graphService: GraphService) { }

  ngOnInit() {
    this.graphService.getGroupsWithPlans().then(groups=>{
      this.groups = groups
    })
  }


}
